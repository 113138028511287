<template>
  <div
    v-if="isOpen"
    class="modal-delete swal2-container swal2-center swal-conection swal2-backdrop-show"
  >
    <div
      class="swal2-popup swal2-modal swal2-show"
      style="display: flex"
    >
      <div class="swal2-header position-relative">
        <div class="swal2-title">{{ $t('IntegrationPage.attention') }}</div>
        <button
          type="button"
          class="swal2-close d-flex"
          aria-label="Close this dialog"
          @click="UPDATE_MODAL_DELETE_SNACK_BAR(false)"
        >
          ×
        </button>
      </div>

      <div
        v-if="isOrder"
        class="swal2-content"
      >
        <p v-html="getDeleteMessage()"></p>
      </div>
      <div
        v-else
        class="swal2-content"
      >
        <p>
          {{
            isDataFields
              ? $t('DataFieldsPage.SnackBarDelete1')
              : $t('DepartmentPage.SnackBarDelete1')
          }}
        </p>
        <p>
          {{ $t('DepartmentPage.SnackBarDelete2') }}
        </p>
        <b-form-input
          v-model="inputValue"
          :placeholder="$t('ResourceStatus.Placeholder')"
          class="swal2-input"
          @input="(e) => verifyEqualName(e)"
        />
      </div>
      <div class="swal2-actions">
        <button
          type="button"
          class="swal2-cancel btn btn-outline-danger ml-1 d-inline-block"
          @click="UPDATE_MODAL_DELETE_SNACK_BAR(false)"
        >
          {{ $t('IntegrationPage.cancel') }}
        </button>
        <button
          type="button"
          class="swal2-confirm btn btn-primary d-inline-block"
          :disabled="!isOrder && disabled"
          @click="onDelete()"
        >
          {{ getButtonText() }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { BFormInput } from 'bootstrap-vue';
  import { mapState, mapMutations } from 'vuex';

  export default {
    components: {
      BFormInput
    },

    props: {
      isDataFields: {
        type: Boolean,
        default: false
      },
      dragList: {
        type: Array
      },
      isOrder: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isOpen: false,
        inputValue: '',
        disabled: true,
        allCheckedItems: [],
        language: this.$cookies.get('userInfo').language
      };
    },
    mounted() {
      this.UPDATE_MODAL_DELETE_SNACK_BAR(false);
    },
    methods: {
      ...mapMutations('departments', ['UPDATE_MODAL_DELETE_SNACK_BAR']),
      verifyEqualName(e) {
        if (
          (e === 'excluir' && this.language === 'pt-BR') ||
          (e === 'delete' && this.language === 'en-US')
        ) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      },
      getButtonText() {
        let text = 'IntegrationPage.delete';
        const isOrderLevel = this.allCheckedItems.some((e) => e.level === 1);

        if (!isOrderLevel && this.isOrder) {
          text = 'IntegrationPage.Unlink';
        }
        return this.$t(text);
      },
      getDeleteMessage() {
        const quantity = this.allCheckedItems.length;
        const isOrderLevel = this.allCheckedItems.some((e) => e.level === 1);
        const pluralOrderName = quantity > 1 ? 'OrdersPage.orders' : 'OrdersPage.order';
        const pluralItemName = quantity > 1 ? 'OrdersPage.items' : 'OrdersPage.item';
        const typeText = isOrderLevel ? 'OrdersPage.DeleteTextOrder' : 'OrdersPage.DeleteTextItems';
        const typeTextLast = isOrderLevel
          ? 'OrdersPage.DeleteTextOrder2'
          : 'OrdersPage.DeleteTextItems2';

        return `${this.$t(typeText)} <b>${quantity} ${this.$t(
          isOrderLevel ? pluralOrderName : pluralItemName
        )}</b>. ${this.$t(typeTextLast)}`;
      },
      onDelete() {
        (!this.disabled || this.isOrder) && this.$emit('confirm');
      },
      getUpdatedLanguage() {
        this.language = this.$cookies.get('userInfo').language;
      },
      getAllCheckedItems(arr) {
        arr.map((e) => {
          if (e.checked) {
            this.allCheckedItems.push(e);
          }
          if (e.child) this.getAllCheckedItems(e.child);
        });
      }
    },
    computed: {
      ...mapState('departments', {
        isModalSnackBarDelete: 'isModalSnackBarDelete'
      })
    },
    watch: {
      isModalSnackBarDelete(v) {
        this.isOpen = v;
        this.getUpdatedLanguage();
        this.getAllCheckedItems(this.dragList);
        if (!v) {
          this.inputValue = '';
          this.disabled = true;
          this.allCheckedItems = [];
        }
      }
    }
  };
</script>
<style lang="scss">
  .swal-conection.modal-delete {
    .swal2-modal {
      padding: 0 !important;
      border-radius: 6px;
      width: 710px;

      .swal2-header {
        background-color: #d32f2f;
        padding: 20px 16px;
        border-radius: 6px 6px 0px 0px;
        flex-direction: row;
        justify-content: left;

        .swal2-title {
          color: #ffff;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          margin: 0;
        }

        .swal2-close {
          border-radius: 6px;
          width: 34px;
          background-color: #ffff;
          height: 34px;
          top: -5px;
          position: absolute;
          right: -5px;
          padding-bottom: 5px;
          padding-left: 2px;
        }
      }

      .swal2-content {
        padding: 20px 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        p {
          text-align: start;
          margin-bottom: 16px;
          font-size: 14px;
        }

        .swal2-html-container {
          font-weight: 400;
          text-align: left;
          font-size: 14px;
          line-height: 20px;
          color: #4c4541;
          display: flex !important;
        }
      }

      .swal2-actions {
        margin: 0;
        padding: 0 16px 20px 16px;
        display: flex;
        justify-content: right;

        .swal2-confirm {
          margin-left: 10px !important;
          order: 2;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #ffff;
          border-color: #974900 !important;
          background-color: #974900 !important;
          border-radius: 5px;

          &:hover {
            box-shadow: none !important;
          }

          &:disabled {
            color: #7e7570 !important;
            font-size: 17px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.213px;
            background: #ece0db !important;
            border-color: #ece0db !important;
          }
        }

        .swal2-cancel {
          order: 1;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #974900 !important;
          border-radius: 5px;
          border-color: transparent !important;

          &:hover {
            background-color: #ffede1 !important;
          }
        }
      }
    }
  }
</style>
